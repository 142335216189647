import { useContext, useState } from 'react';
import '../../styles/taskTable.scss';
import TaskTableCard from './TaskTableCard';
import { SvgSelector } from '../../helper/svg-selector';
import AddTask from '../Dashboard/AddTask';
import AnimationSlide from '../Elements/AnimationSlide';
import MenuSelectBoard from '../Elements/MenuSelectBoard';
import ProOptionCard from '../ProAccount/ProOptionCard';
import { DataContext } from '../../context/DataContext';
import DatePicker from '../Elements/DatePicker';
import StatusMenu from '../Elements/StatusMenu';
import FilterTag from '../Elements/FilterTag';
import MenuSelectMember from '../Elements/MenuSelectMember';
import { deadlineCal } from '../../helper/functions';

const thList = [
  { id: 1, name: '№', width: '1%', hasIcon: false, value: '' },
  {
    id: 2,
    name: 'Plotting World Domination',
    width: '20%',
    hasIcon: false,
    value: '',
  },
  { id: 3, name: 'Board', width: '1%', hasIcon: true, value: 'board' },
  { id: 4, name: 'Parent', width: '1%', hasIcon: false, value: '' },
  { id: 5, name: 'Stage', width: '5%', hasIcon: true, value: 'stage' },
  { id: 6, name: 'Tags', width: '5%', hasIcon: true, value: 'tags' },
  { id: 7, name: 'Assignee', width: '6%', hasIcon: true, value: 'assignee' },
  { id: 8, name: 'Due Date', width: '7%', hasIcon: true, value: 'deadline' },
  { id: 9, name: 'Actions', width: '7%', hasIcon: false, value: '' },
];

function TableBody({
  tasks,
  topic,
  day,
  type,
  setCheckMembers,
  taskFilter,
  setTaskFilter,
  memberList,
  addNewTask,
  setToastInfo,
  toastInfo,
  tasksDate,
  updateStatus,
  deleteTask,
  duplicateTask,
}) {
  const { isProUser } = useContext(DataContext);

  const [openMenu, setOpenMenu] = useState('');

  const onClickFilter = (filter) => {
    if (type !== 'allTasks') return;
    setOpenMenu(filter);
  };

  const proCard = (
    <ProOptionCard
      closeMenu={setOpenMenu}
      proText="Want to use quick filters? Sign up for a tariff level right now!"
    />
  );

  const selectFilterBoard = (id) => {
    setTaskFilter((prev) => ({
      ...prev,
      board_id: [...prev.board_id, id],
    }));
  };

  const selectFilterStatus = (status) => {
    setTaskFilter((prev) => {
      if (prev.status.includes(status.value)) {
        return prev;
      }
      return {
        ...prev,
        status: [...prev.status, status.value],
      };
    });
  };

  const selectFilterAssign = (info) => {
    if (taskFilter.assignments.userNames) {
      setTaskFilter((prev) => {
        if (prev.assignments.userId.includes(info.id)) {
          return prev;
        }
        return {
          ...prev,
          assignments: {
            ...prev.assignments,
            userId: [...prev.assignments.userId, info.id],
            userNames: [...prev.assignments.userId, info.id],
            userEmail: [...prev.assignments.userEmail, info.email],
            display_userNames: [...prev.assignments.userEmail, info.email],
          },
        };
      });
    } else {
      setTaskFilter((prev) => ({
        ...prev,
        assignments: {
          ...prev.assignments,
          userId: [info.id],
          userNames: [info.id],
          userEmail: [info.email],
          display_userNames: [info.email],
        },
      }));
    }
  };

  const selectFilterDeadLine = (tStartDate, tDueDate) => {
    let startTemp;
    startTemp = tStartDate;
    if (tDueDate && !tStartDate) {
      startTemp = tDueDate;

      const givenDate = new Date(startTemp);
      const lastDate = new Date(givenDate.getTime() - 24 * 60 * 60 * 1000);
      const lastSameDate = lastDate.toISOString();
      const newDate = new Date(givenDate.getTime());
      const sameDate = newDate.toISOString();

      setTaskFilter((prev) => ({
        ...prev,
        due_date: {
          ...prev.due_date,
          due_timeGt: [lastSameDate],
          due_timeIt: [sameDate],
          display_date: [
            startTemp ? deadlineCal(tDueDate)[0] : '',
            tDueDate ? deadlineCal(sameDate)[0] : '',
          ],
        },
      }));
    }

    if (tDueDate && tStartDate) {
      const sT = new Date(tStartDate.getTime());
      const startDate = sT.toISOString();
      const dT = new Date(tDueDate.getTime());
      const dueDate = dT.toISOString();
      setTaskFilter((prev) => ({
        ...prev,
        due_date: {
          ...prev.due_date,
          due_timeGt: [startDate],
          due_timeIt: [dueDate],
          display_date: [
            startTemp ? deadlineCal(tStartDate)[0] : '',
            tDueDate ? deadlineCal(tDueDate)[0] : '',
          ],
        },
      }));
    }
    setOpenMenu('');
  };

  const selectFilterTag = (tag) => {
    setTaskFilter((prev) => {
      if (prev.tag.includes(tag.setColor)) {
        return prev;
      }
      return {
        ...prev,
        tag: [...prev.tag, tag.setColor],
      };
    });
  };
  return (
    <div className="table-body-container">
      {topic && (
        <div className="table-body-topic">
          <span
            className="font-16-bold"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {day}
          </span>
          <span
            className="table-body-topic-text font-16-reg"
            style={{ color: type === 'byBoards' ? 'var(--black)' : '' }}
          >
            {topic}
          </span>
        </div>
      )}
      <table className="all-task-container">
        <tr className="task-table-tr">
          {thList.map((th) => {
            if (!th.hasIcon)
              return (
                <th
                  className="task-table-th"
                  key={th.id}
                  style={{
                    width: th.width,
                  }}
                >
                  <span className="font-14-reg task-filter-setting-name">
                    {th.name}
                  </span>
                </th>
              );
            return (
              <th
                className={`task-table-th ${
                  type === 'allTasks' ? 'cursor-pointer' : ''
                }`}
                style={{
                  width: th.width,
                  minWidth: th.id === 8 ? 132 : th.id === 6 ? 85 : '',
                }}
                onClick={() => onClickFilter(th.value)}
                key={th.id}
              >
                <div className="task-filter-setting-name">
                  {type === 'allTasks' &&
                    SvgSelector('settings', 'task-filter-setting-icon')}
                  <span className="font-14-reg task-filter-name-color">
                    {th.name}
                  </span>
                </div>
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'board' && th.id === 3}
                  state="topToDown"
                >
                  {isProUser ? (
                    <div className="board-table-filter-pos">
                      <MenuSelectBoard
                        selectBoard={selectFilterBoard}
                        filterId={th.id}
                      />
                    </div>
                  ) : (
                    <div className="board-table-filter-pro-pos">{proCard}</div>
                  )}
                </AnimationSlide>

                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'deadline' && th.id === 8}
                  state="topToDown"
                >
                  <div className="date-table-filter-pos">
                    {isProUser ? (
                      <DatePicker node={1} onClose={selectFilterDeadLine} />
                    ) : (
                      <div>{proCard}</div>
                    )}
                  </div>
                </AnimationSlide>

                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'stage' && th.id === 5}
                  state="topToDown"
                >
                  <div className="status-table-filter-pos">
                    {isProUser ? (
                      <StatusMenu handleClickItem={selectFilterStatus} />
                    ) : (
                      <div>{proCard}</div>
                    )}
                  </div>
                </AnimationSlide>

                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'tags' && th.id === 6}
                  state="topToDown"
                >
                  <div className="tags-table-filter-pos">
                    {isProUser ? (
                      <FilterTag onSelectItem={selectFilterTag} />
                    ) : (
                      <div>{proCard}</div>
                    )}
                  </div>
                </AnimationSlide>

                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'assignee' && th.id === 7}
                  state="topToDown"
                >
                  <div className="members-table-filter-pos">
                    {isProUser ? (
                      <MenuSelectMember
                        data={memberList}
                        setFilterTask={selectFilterAssign}
                      />
                    ) : (
                      <div>{proCard}</div>
                    )}
                  </div>
                </AnimationSlide>
              </th>
            );
          })}
        </tr>
        <tr className="task-table-tr">
          <td
            className="task-table-td"
            colSpan="2"
            style={{ textAlign: 'left' }}
          >
            {' '}
            <AddTask newTask={addNewTask} tasksDate={tasksDate} type={type} />
          </td>
          {Array.from({ length: 7 }).map((i) => (
            <td className="task-table-td" key={i}>{` `}</td>
          ))}
        </tr>
        {tasks?.map((task, index) => (
          <TaskTableCard
            key={task}
            dataId={task}
            index={index}
            setCheckMembers={setCheckMembers}
            setToastInfo={setToastInfo}
            toastInfo={toastInfo}
            updateStatus={updateStatus}
            deleteTask={deleteTask}
            duplicateTask={duplicateTask}
            type={type}
            tasksDate={tasksDate}
          />
        ))}
        <tr className="task-table-tr">
          {Array.from({ length: 9 }).map((i) => (
            <td className="task-table-td" key={i}>{` `}</td>
          ))}
        </tr>
      </table>
    </div>
  );
}

export default TableBody;
