import { Emoji } from 'emoji-picker-react';
import '../../styles/taskTabs.scss';

function TaskTabs({ list, changedTab, setChangedTab }) {
  return (
    <div className="task-tabs-container">
      {list.map((tab) => (
        <div
          onClick={() => setChangedTab(tab.key)}
          style={{ width: tab.id === 2 ? 90 : 108 }}
          className={`task-tabs-item cursor-pointer ${changedTab === tab.key ? 'task-tabs-item-selected' : ''}`}
        >
          <Emoji unified={tab.emoji} size={24} />
          <span
            className={`task-tabs-item-text ${changedTab === tab.key ? 'font-14-bold' : 'font-14-reg'}`}
          >
            {tab.name}
          </span>
        </div>
      ))}
    </div>
  );
}
export default TaskTabs;
