import TableBody from './TableBody';

function ByBoards({
  setCheckMembers,
  memberList,
  taskFilter,
  setTaskFilter,
  sectionList,
  sectionData,
  addNewTask,
  setToastInfo,
  toastInfo,
  updateStatus,
  deleteTask,
  duplicateTask,
}) {
  return (
    <div className="by-boards-container">
      {sectionList.map((i) => {
        if (sectionData(i.id).length !== 0)
          return (
            <TableBody
              addNewTask={addNewTask}
              tasks={sectionData(i.id)}
              setCheckMembers={setCheckMembers}
              memberList={memberList}
              setTaskFilter={setTaskFilter}
              taskFilter={taskFilter}
              topic={i.title}
              day={i.icon}
              type="byBoards"
              setToastInfo={setToastInfo}
              toastInfo={toastInfo}
              updateStatus={updateStatus}
              deleteTask={deleteTask}
              duplicateTask={duplicateTask}
            />
          );
        return null;
      })}
    </div>
  );
}

export default ByBoards;
