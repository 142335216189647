import { useContext, useRef, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import Button from './Button';
import dataServices from '../../services/dataServices';
import { DataContext } from '../../context/DataContext';

function ModalImport({
  openModal,
  setUploadingFile,
  navigateAddData,
  currentProject,
  allData,
  setAllData,
  shownImportModal,
  setToastInfo,
}) {
  const { isProUser, totalObjects, setTotalObjects } = useContext(DataContext);

  const fileInput = useRef(null);
  const [file, setFile] = useState();
  const [textError, setTextError] = useState('');
  const chooseFileHandler = () => {
    fileInput.current.click();
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const uploadingBoard = (name) => {
    let temp = [];
    temp = [...allData];
    const newObj = {
      title: [{ text: name }],
      type: 'UPLOADING',
      parent: currentProject.id,
    };
    temp.unshift(newObj);
    setAllData(temp);
  };

  const uploadFailedHandler = (textErr) => {
    setToastInfo({
      text: textErr,
      isLoading: false,
      actionText: 'Ok',
      action: null,
    });
    setUploadingFile(false);
    const temp = [...allData];
    temp.shift();
    setAllData(temp);
  };

  const importHandler = () => {
    if (!file) return;
    if (!isProUser && totalObjects.totalBoards >= 15) {
      setTextError('Only 15 board.Use PRO without restrictions!');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    // setUploadingFile(true);
    // uploadingBoard(file);
    // openModal(false);
    if (shownImportModal === 'importModalAi') {
      if (!file.type.includes('pdf')) {
        setTextError('please upload pdf file');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setTextError('please upload file less than 5MB');
        return;
      }
      const aiParams = {
        file,
        gpt_version: 3,
      };
      setUploadingFile(true);
      uploadingBoard(file);
      openModal(false);
      dataServices
        .extractFile(currentProject?.id, aiParams)
        .then((res) => {
          if (res.status === 200) {
            setUploadingFile(false);
            setTotalObjects((old) => ({
              totalBoards: old.totalBoards + 1,
              totalProjects: old.totalProject,
            }));
            navigateAddData(res.data.id);
          } else {
            uploadFailedHandler('invalid file');
          }
        })
        .catch((error) => {
          uploadFailedHandler(error.response.data.detail);
        });
    } else if (shownImportModal === 'importModal') {
      if (!file.name.includes('layer')) {
        setTextError('please upload layer file');
        return;
      }
      setUploadingFile(true);
      uploadingBoard(file);
      openModal(false);
      // dataServices
      //   .importFile(currentProject?.id, { file })
      //   .then((res) => {
      //     if (res.status === 200) {
      //       setUploadingFile(false);
      //       navigateAddData(res.data.id);
      //     } else uploadFailedHandler();
      //   })
      //   .catch(uploadFailedHandler);
      dataServices
        .importFile(currentProject?.id, { file })
        .then((res) => {
          if (res.status === 200) {
            setUploadingFile(false);
            navigateAddData(res.data.id);
          } else uploadFailedHandler('Invalid file');
        })
        .catch((error) => {
          uploadFailedHandler(error.response.data.detail);
        });
    }
  };

  return (
    <div
      className="modal-container"
      aria-hidden="true"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          openModal(false);
        }
      }}
    >
      <div className="modal-wrapper modal-export-container">
        <div className="modal-header">
          <div className="font-16-bold">Import</div>
          <button
            type="button"
            className="cursor-pointer center"
            onClick={() => openModal(false)}
          >
            {SvgSelector('close')}
          </button>
        </div>
        <div className="modal-export-download-container">
          <div className="flex-row cursor-pointer modal-export-download-check">
            <Button
              labelClass="font-12-bold"
              label="Choose File"
              textColor="white"
              color="purple"
              onClick={chooseFileHandler}
              width={100}
              height={36}
            />
            <input
              ref={fileInput}
              type="file"
              className="hidden"
              onChange={handleChange}
            />
            <div className="font-14-reg modal-export-download-text">
              {file?.name || 'No file selected'}
            </div>
          </div>
          {shownImportModal !== 'importModalAi' && (
            <div
              className={`font-14-med ${textError ? 'import-has-error' : 'import-without-error'}`}
              style={{ marginTop: '10px' }}
            >
              please upload layer file
            </div>
          )}
        </div>

        <div className="modal-buttons modal-export-buttons">
          <Button
            labelClass="font-12-bold"
            label="Cancel"
            textColor="black"
            color="gray_06"
            onClick={() => openModal(false)}
            width="47%"
            height={36}
          />
          <Button
            labelClass="font-12-bold"
            label="Select"
            textColor="white"
            color="blue_strong"
            onClick={importHandler}
            width="47%"
            height={36}
          />
        </div>
      </div>
    </div>
  );
}
export default ModalImport;
