import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

const useMobileState = (threshold) => {
  const [isMobile, setIsMobile] = useState(threshold <= 670);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (threshold <= 670) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }, 50);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold]);
  return isMobile;
};

export default useMobileState;
