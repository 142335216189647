import TableBody from './TableBody';

function AllTask({
  tasks,
  setCheckMembers,
  memberList,
  taskFilter,
  setTaskFilter,
  addNewTask,
  setToastInfo,
  toastInfo,
  updateStatus,
  deleteTask,
  duplicateTask,
}) {
  return (
    <div className="all-tasks-container">
      <TableBody
        addNewTask={addNewTask}
        tasks={tasks}
        type="allTasks"
        setCheckMembers={setCheckMembers}
        memberList={memberList}
        setTaskFilter={setTaskFilter}
        taskFilter={taskFilter}
        setToastInfo={setToastInfo}
        toastInfo={toastInfo}
        updateStatus={updateStatus}
        deleteTask={deleteTask}
        duplicateTask={duplicateTask}
      />
    </div>
  );
}

export default AllTask;
