import { useContext, useState, useEffect } from 'react';
import { Emoji } from 'emoji-picker-react';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/taskTable.scss';
import TaskTabs from './TaskTabs';
import MyFocus from './MyFocus';
import AllTask from './AllTask';
import ByBoards from './ByBoards';
import dataServices from '../../services/dataServices';
import { DataContext } from '../../context/DataContext';
import Loading from '../Elements/Loading';
import SmartFilter from '../Dashboard/SmartFilter';
import Toast from '../Elements/Toast';
import { layerProps } from '../../constants/objects';
import { setTextLimit } from '../../helper/functions';
import boardFilter from '../../assets/png/board-filter.png';

const taskListTab = [
  { id: 1, name: 'My Focus', key: 'myFocus', emoji: '1f31e' },
  { id: 2, name: 'All task', key: 'allTask', emoji: '1f4e5' },
  { id: 3, name: 'By boards', key: 'byBoards', emoji: '1f5c3-fe0f' },
];
const todayTasksIds = [];
function TaskTable() {
  const { treeData, dispatch, setOriginData, originData, boardId } =
    useContext(DataContext);

  const user = JSON.parse(localStorage.getItem('user'));
  const rootId = user?.root?.id;

  const [changedTab, setChangedTab] = useState('myFocus');
  const [isLoading, setLoading] = useState(true);
  const [taskFilter, setTaskFilter] = useState({
    board_id: [],
    tag: [],
    status: [],
    assignments: {},
  });
  const [myFocusTasks, setMyFocusTasks] = useState({});
  const [tasks, setTasks] = useState([]);
  const [checkMembers, setCheckMembers] = useState(false);
  const [myFocusOffset, setMyFocusOffset] = useState(31);
  const [membersList, setMembersList] = useState([]);
  const [todayTasks, setTodayTasks] = useState([]);
  const [toastInfo, setToastInfo] = useState({
    text: null,
    isLoading: false,
    actionText: 'Okay',
    action: null,
  });
  const getCalendarDays = (daysCount) => {
    const today = new Date();
    const daysArray = Array.from({ length: daysCount }, (_, i) => i);

    const result = [];
    let lastMonth = null;

    daysArray.forEach((i) => {
      const currentDay = new Date(today);
      currentDay.setDate(today.getDate() + i);

      if (i < 7) {
        let dayName = null;
        if (i === 0) {
          dayName = 'Today';
        } else if (i === 1) {
          dayName = 'Tomorrow';
        } else {
          dayName = currentDay.toLocaleDateString('en-US', {
            weekday: 'long',
          });
        }

        result.push({
          name: dayName,
          day: currentDay.getDate(),
          month: currentDay.toLocaleDateString('en-US', { month: 'long' }),
          year: currentDay.getFullYear(),
          currentDay: new Date(currentDay),
        });
      } else {
        const currentMonth = currentDay.getMonth();

        if (currentMonth !== lastMonth) {
          const startDate = new Date(currentDay);

          const endDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            0,
          );

          if (endDate.getDate() - startDate.getDate() + 1 <= daysCount - i) {
            result.push({
              name: `${startDate.toLocaleDateString('en-US', { month: 'long' })} ${startDate.getFullYear()}`,
              day: `${startDate.getDate()} - ${endDate.getDate()}`,
              month: startDate.toLocaleDateString('en-US', { month: 'long' }),
              year: startDate.getFullYear(),
              currentDay: new Date(startDate),
              currentEndDay: new Date(endDate),
            });

            // eslint-disable-next-line
            i += endDate.getDate() - startDate.getDate();
          } else {
            result.push({
              name: `${startDate.toLocaleDateString('en-US', { month: 'long' })} ${startDate.getFullYear()}`,
              day: `${startDate.getDate()} - ${endDate.getDate()}`,
              month: startDate.toLocaleDateString('en-US', { month: 'long' }),
              year: startDate.getFullYear(),
              currentDay: new Date(startDate),
              currentEndDay: new Date(endDate),
            });

            // eslint-disable-next-line
            i += endDate.getDate() - startDate.getDate();
          }

          lastMonth = currentMonth;
        }
      }
    });

    return result;
  };

  const calendarData = getCalendarDays(myFocusOffset);

  useEffect(() => {
    const handleScroll = () => {
      if (changedTab !== 'myFocus') return;
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 50
      ) {
        setMyFocusOffset((prev) => prev + 31);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [changedTab]);

  const getFormattedDate = (offsetDays = 0) => {
    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset();

    date.setMinutes(date.getMinutes() - timezoneOffset);
    date.setDate(date.getDate() + offsetDays);
    return date.toISOString().split('T')[0];
  };
  const today = getFormattedDate();

  const getDateWithOffset = (offset = 0) => {
    const date = new Date();

    const timezoneOffset = date.getTimezoneOffset();

    date.setMinutes(date.getMinutes() - timezoneOffset);

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const newDate = new Date(Date.UTC(year, month, day + offset));

    newDate.setUTCHours(0, 0, 0, 0);
    return newDate.toISOString();
  };
  const getTogoData = () => {
    const filter = {
      board_id: [],
      tag: [],
      status: ['TOGO'],
      assignments: {},
    };
    dataServices.getTasks(filter).then((res) => {
      res.data.map((task) => {
        treeData[task.id] = task;
        todayTasksIds.push(task.id);
        return treeData;
      });
      const uniqueFocus = [...new Set(todayTasksIds)];
      setTodayTasks(uniqueFocus);
    });
  };

  const getFirstWeekTasks = () => {
    const filter = {
      board_id: [],
      tag: [],
      status: [],
      assignments: {},
      due_date: {
        due_timeGt: [getDateWithOffset()],
        due_timeIt: [getDateWithOffset(myFocusOffset)],
      },
    };
    dataServices.getTasks(filter).then((res) => {
      res.data.map((task) => {
        treeData[task.id] = task;
        const taskDate = task.due_time.split('T')[0];
        setMyFocusTasks((prev) => {
          const currentTasks = prev[taskDate] || [];

          if (!currentTasks.includes(task.id)) {
            return {
              ...prev,
              [taskDate]: [...currentTasks, task.id],
            };
          }

          return prev;
        });
        if (taskDate === today) {
          todayTasksIds.push(task.id);
        }
        return treeData;
      });
      dispatch({ type: 'setTree', payload: treeData });
      const uniqueFocus = [...new Set(todayTasksIds)];
      setTodayTasks(uniqueFocus);
      setLoading(false);
    });
  };

  const getTasksWithDate = () => {
    const filter = {
      board_id: [],
      tag: [],
      status: [],
      assignments: {},
      due_date: {
        due_timeGt: [getDateWithOffset(myFocusOffset - 31)],
        due_timeIt: [getDateWithOffset(myFocusOffset)],
      },
    };
    dataServices.getTasks(filter).then((res) => {
      res.data.map((task) => {
        treeData[task.id] = task;
        const taskDate = task.due_time.split('T')[0];
        setMyFocusTasks((prev) => {
          const currentTasks = prev[taskDate] || [];

          if (!currentTasks.includes(task.id)) {
            return {
              ...prev,
              [taskDate]: [...currentTasks, task.id],
            };
          }

          return prev;
        });
        if (taskDate === today) {
          todayTasksIds.push(task.id);
        }
        return treeData;
      });
      dispatch({ type: 'setTree', payload: treeData });
      const uniqueFocus = [...new Set(todayTasksIds)];
      setTodayTasks(uniqueFocus);
      setLoading(false);
    });
  };

  const getData = () => {
    const taskOrders = [];
    dataServices.getTasks(taskFilter).then((res) => {
      res.data.map((task) => {
        treeData[task.id] = task;
        taskOrders.push(task.id);
        return treeData;
      });
      dispatch({ type: 'setTree', payload: treeData });
      setTasks(taskOrders);
      setLoading(false);
    });
  };

  const getBoards = () => {
    dataServices
      .getLayerDescendants(rootId, { layer_type__in: 'BOARD' })
      .then((res) => {
        setOriginData(res.data);
      });
  };

  const getAllMembers = () => {
    dataServices.getAllAssignments().then((res) => setMembersList(res.data));
  };

  const sectionList = originData.map((i) => ({
    title: i.title[0].text,
    id: i.id,
    icon: <img src={boardFilter} alt="boardFilter" />,
  }));
  sectionList.unshift({ title: '', id: 'unAssignTasks' });

  const sectionData = (id) => {
    if (id === 'unAssignTasks') {
      return tasks.filter(
        (i) =>
          treeData[i].board_id === null || treeData[i].board_id === undefined,
      );
    }
    return tasks.filter((i) => id === treeData[i].board_id);
  };

  const newAllTask = (taskName) => {
    const newChildID = uuidv4();
    const newChild = {
      ...layerProps,
      id: newChildID,
      parent: boardId,
      title: [{ text: taskName }],
      color: 'PINK',
      layer_type: 'TASK',
      status: 'BACKLOG',
      assignments: [],
      parent_title: [{ text: 'None' }],
    };
    dataServices.addLayers(newChild).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'New Task created',
        actionText: 'Okay',
        action: null,
      });

      setTasks((prevState) => [newChildID, ...prevState]);
      treeData[newChildID] = newChild;
    });
  };

  const newMyFocusTask = (taskName, dueTime, type) => {
    const newChildID = uuidv4();
    const newChild = {
      ...layerProps,
      id: newChildID,
      parent: boardId,
      title: [{ text: taskName }],
      color: 'PINK',
      layer_type: 'TASK',
      status: 'BACKLOG',
      assignments: [],
      due_time: `${dueTime}T00:00:00.000Z`,
      parent_title: [{ text: 'None' }],
    };
    dataServices.addLayers(newChild).then(() => {
      setToastInfo({
        ...toastInfo,
        isLoading: false,
        text: 'New Task created',
        actionText: 'Okay',
        action: null,
      });
      const taskDate = dueTime.split('T')[0];

      setMyFocusTasks((prev) => ({
        ...prev,
        [taskDate]: [newChildID, ...(prev[taskDate] || [])],
      }));

      if (type === 'todayTasks') {
        setTodayTasks((prev) => [newChildID, ...prev]);
      }
      setTasks((prevState) => [newChildID, ...prevState]);
      treeData[newChildID] = newChild;
    });
  };

  const updateStatus = (status, id) => {
    const tempToday = [...todayTasks];
    const idx = tempToday.findIndex((i) => i === id);
    if (status === 'TOGO') {
      if (idx === -1) {
        setTodayTasks((prev) => [...prev, id]);
      }
    }
    const dueTimeTask = treeData[id]?.due_time?.split('T')[0];
    if (status !== 'TOGO' && dueTimeTask !== today) {
      const removeTask = tempToday.filter((i) => i !== id);
      setTodayTasks(removeTask);
    }
  };

  const deleteTask = (id) => {
    const tempTodayTasks = [...todayTasks];
    const tempTasks = [...tasks];
    const item = treeData[id];
    const tempFocusTask = { ...myFocusTasks };
    dataServices.deleteLayer(item.id).then(() => {
      setToastInfo({
        isLoading: false,
        text: `${setTextLimit(item.title[0].text)} Deleted`,
        actionText: 'Delete',
        extra: { item: item.id },
      });
      const deletedTodayTask = tempTodayTasks.filter((i) => i !== id);
      const deletedTask = tempTasks.filter((i) => i !== id);
      setTodayTasks(deletedTodayTask);
      setTasks(deletedTask);
      if (item.due_time) {
        const dueTime = item.due_time.split('T')[0];
        const deletedFocusTask = tempFocusTask[dueTime]?.filter(
          (i) => i !== id,
        );
        setMyFocusTasks((prev) => ({
          ...prev,
          [dueTime]: deletedFocusTask,
        }));
      }
    });
  };

  const updateTodayTasks = (item) => {
    setTodayTasks((prevState) => [item, ...prevState]);
  };

  const updateTasks = (item) => {
    setTasks((prevState) => [item, ...prevState]);
  };

  const duplicateTask = (id, type, taskDate) => {
    const newChildID = uuidv4();
    const task = treeData[id];
    const layer = [{ ...task }];
    layer[0].id = newChildID;
    layer[0].previous_id = task.board_id ? id : null;
    layer[0].previous = task.board_id ? id : null;
    layer[0].parent_id = task.parent;
    const params = {
      layers: layer,
      parent: task.parent,
      pk: newChildID,
    };
    dataServices.duplicateLayer(id, params).then(() => {
      // eslint-disable-next-line
      treeData[newChildID] = layer[0];
      if (type === 'todayTasks') {
        updateTodayTasks(newChildID);
      } else if (type === 'focusWithDate') {
        const tempFocus = [...myFocusTasks[taskDate]];
        tempFocus.unshift(newChildID);
        setMyFocusTasks((prev) => ({ ...prev, [taskDate]: tempFocus }));
      } else {
        updateTasks(newChildID);
      }
    });
  };

  useEffect(() => {
    getBoards();
    getFirstWeekTasks();
    getTogoData();
  }, []);

  useEffect(() => {
    getAllMembers();
  }, [checkMembers]);

  useEffect(() => {
    if (myFocusOffset === 31) return;
    getTasksWithDate();
  }, [myFocusOffset]);

  useEffect(() => {
    getData();
  }, [taskFilter]);

  const showTabItem = {
    myFocus: (
      <MyFocus
        setTaskFilter={setTaskFilter}
        myFocusTasks={myFocusTasks}
        setCheckMembers={setCheckMembers}
        memberList={membersList}
        taskFilter={taskFilter}
        calendarData={calendarData}
        todayTasks={todayTasks}
        setToastInfo={setToastInfo}
        toastInfo={toastInfo}
        addNewTask={newMyFocusTask}
        updateStatus={updateStatus}
        deleteTask={deleteTask}
        duplicateTask={duplicateTask}
      />
    ),
    allTask: (
      <AllTask
        tasks={tasks}
        setTaskFilter={setTaskFilter}
        setCheckMembers={setCheckMembers}
        checkMembers={checkMembers}
        taskFilter={taskFilter}
        addNewTask={newAllTask}
        setToastInfo={setToastInfo}
        toastInfo={toastInfo}
        updateStatus={updateStatus}
        deleteTask={deleteTask}
        duplicateTask={duplicateTask}
      />
    ),
    byBoards: (
      <ByBoards
        tasks={tasks}
        setTaskFilter={setTaskFilter}
        setCheckMembers={setCheckMembers}
        checkMembers={checkMembers}
        taskFilter={taskFilter}
        sectionList={sectionList}
        sectionData={sectionData}
        setToastInfo={setToastInfo}
        toastInfo={toastInfo}
        updateStatus={updateStatus}
        deleteTask={deleteTask}
        duplicateTask={duplicateTask}
      />
    ),
  };

  if (isLoading) return <Loading />;
  return (
    <div className="task-page-container">
      <div className="task-page-title-container">
        <div className="task-page-name-container font-h2">
          <Emoji unified="1f31e" size={36} />
          All in one spot
          <SmartFilter
            setTaskFilter={setTaskFilter}
            dataObject={originData}
            taskFilter={taskFilter}
          />
        </div>
        <span className="font-14-reg task-page-title-feature">
          Easily filter by deadline, status, assignee, or tags
        </span>
      </div>
      <div>
        <TaskTabs
          list={taskListTab}
          changedTab={changedTab}
          setChangedTab={setChangedTab}
        />
      </div>
      {showTabItem[changedTab]}
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} />
      )}
    </div>
  );
}

export default TaskTable;
