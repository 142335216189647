import { useNavigate } from 'react-router-dom';
import '../../styles/proAccount.scss';

function ProOptionCard({ proText, closeMenu }) {
  const navigate = useNavigate(null);
  return (
    <div className="pro-option-container box-shadow">
      <div className="pro-option-header">
        <span className="font-14-med">PRO option</span>
        <span
          className="pro-option-header-close font-14-med cursor-pointer"
          onClick={() => closeMenu(false)}
        >
          Close
        </span>
      </div>
      <div className="pro-option-description">
        <div className="pro-option-logo-container">
          <span className="pro-option-logo" />
        </div>
        <span className="font-16-med pro-option-text">{proText}</span>
        <div className="pro-option-button-container">
          <div
            className="cursor-pointer pro-option-button tariff-choose-plan font-14-bold"
            onClick={() => navigate('/plans')}
          >
            <span>View PRO plan</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProOptionCard;
