import { useContext, useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
// eslint-disable-next-line
import 'react-day-picker/style.css';
import SwitchButton from './SwitchButton';
import '../../styles/datePicker.scss';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';
import { zeroPad } from '../../helper/calendar';
import { reminderItems } from '../../constants/arrays';

function Input({ value, placeholder, clearValue }) {
  const convertDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const formattedDate = convertDate(value);
  return (
    <div className="input-container">
      <input
        type="text"
        className="input-item font-14-reg"
        value={formattedDate}
        disabled
        placeholder={placeholder}
      />
      {value && (
        <div onClick={clearValue}>
          {SvgSelector('close', 'input-icon cursor-pointer')}
        </div>
      )}
    </div>
  );
}

function TimeInput({ hour, minute, midday, onChangeTime, onBlur, clearTime }) {
  const hourRef = useRef(null);
  const minRef = useRef(null);
  const middayRef = useRef(null);

  const changeHour = (val) => {
    onChangeTime(val, 'hour');
    const regex = /^$|^[0-9\b]+$/;
    if (!regex.test(val)) return;
    if (val.length > 1) {
      minRef?.current.select();
      minRef?.current.focus();
    }
  };

  const changeMinute = (val) => {
    onChangeTime(val, 'minute');
    const regex = /^$|^[0-9\b]+$/;
    if (!regex.test(val)) return;
    if (val.length > 1) {
      middayRef?.current.select();
      middayRef?.current.focus();
    }
  };

  const changeMidday = (val) => {
    onChangeTime(val, 'midday');
  };

  return (
    <div
      className="input-container"
      // onClick={onDueTimeSelect}
    >
      <div className="calendar-included-time-container">
        <input
          ref={hourRef}
          className="calendar-input-time font-14-reg"
          value={hour}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => changeHour(e.target.value)}
          maxLength={2}
          onBlur={(e) => onBlur(e.target.value, 'hour')}
        />
        <div className="calendar-input-time-semi">:</div>
        <input
          ref={minRef}
          className="calendar-input-time font-14-reg"
          value={minute}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => changeMinute(e.target.value)}
          onBlur={(e) => onBlur(e.target.value, 'minute')}
          maxLength={2}
        />
        <input
          ref={middayRef}
          className="calendar-input-time mh-5 w-25 font-14-reg"
          onClick={(e) => e.stopPropagation()}
          value={midday}
          onChange={(e) => changeMidday(e.target.value)}
          onBlur={(e) => {
            onBlur(e.target.value, 'midday');
          }}
          maxLength={2}
        />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          clearTime();
        }}
      >
        {SvgSelector('close', 'input-icon cursor-pointer')}
      </div>
    </div>
  );
}

function ControlInputs({
  selectedDate,
  setSelectedDate,
  startTime,
  setStartTime,
  dueTime,
  setDueTime,
  includeTime,
  hasRange,
}) {
  const changeStartTimeHandler = (val, item) => {
    if (item === 'midday') {
      const value = val.toUpperCase();
      const regex = /^$|^[A|P]{1}M*$/;
      if (regex.test(value))
        setStartTime((prevState) => ({ ...prevState, [item]: value }));
      // saveDatePickerData('startTime', { ...startTime, [item]: value });
    } else {
      const regex = /^$|^[0-9\b]+$/;
      if (!regex.test(val)) return;
      setStartTime((prevState) => ({ ...prevState, [item]: val }));
      // saveDatePickerData('startTime', { ...startTime, [item]: val });
    }
  };

  const changeDueTimeHandler = (val, item) => {
    if (item === 'midday') {
      const value = val.toUpperCase();
      const regex = /^$|^[A|P]{1}M*$/;
      if (regex.test(value))
        setDueTime((prevState) => ({ ...prevState, [item]: value }));
      // saveDatePickerData('dueTime', { ...dueTime, [item]: value });
    } else {
      const regex = /^$|^[0-9\b]+$/;
      if (!regex.test(val)) return;
      setDueTime((prevState) => ({ ...prevState, [item]: val }));
      // saveDatePickerData('dueTime', { ...dueTime, [item]: val });
    }
  };

  const onBlurHandlerStart = (value, item) => {
    if (item === 'hour') {
      if (value > 23 || value < 1) {
        changeStartTimeHandler(12, 'hour');
      } else if (value > 12) {
        changeStartTimeHandler(zeroPad(value % 12, 2), 'hour');
        changeStartTimeHandler('PM', 'midday');
      } else {
        changeStartTimeHandler(zeroPad(value, 2), 'hour');
      }
    } else if (item === 'minute') {
      changeStartTimeHandler(zeroPad(value > 60 ? 0 : value, 2), 'minute');
    } else if (item === 'midday' && value.length < 2) {
      if (value === 'P') changeStartTimeHandler('PM', 'midday');
      else changeStartTimeHandler('AM', 'midday');
    }
  };

  const onBlurHandlerDueTime = (value, item) => {
    if (item === 'hour') {
      if (value > 23 || value < 1) {
        changeDueTimeHandler(12, 'hour');
      } else if (value > 12) {
        changeDueTimeHandler(zeroPad(value % 12, 2), 'hour');
        changeDueTimeHandler('PM', 'midday');
      } else {
        changeDueTimeHandler(zeroPad(value, 2), 'hour');
      }
    } else if (item === 'minute') {
      changeDueTimeHandler(zeroPad(value > 60 ? 0 : value, 2), 'minute');
    } else if (item === 'midday' && value.length < 2) {
      if (value === 'P') changeDueTimeHandler('PM', 'midday');
      else changeDueTimeHandler('AM', 'midday');
    }
  };

  const clearStartTime = () => {
    onBlurHandlerStart('', 'hour');
    onBlurHandlerStart('', 'minute');
    onBlurHandlerStart('', 'midday');
  };

  const clearDueTime = () => {
    onBlurHandlerDueTime('', 'hour');
    onBlurHandlerDueTime('', 'minute');
    onBlurHandlerDueTime('', 'midday');
  };

  return (
    <div>
      {hasRange === 'single' && !includeTime && (
        <div>
          <Input
            value={selectedDate}
            placeholder="Select date"
            clearValue={() => setSelectedDate(null)}
          />
        </div>
      )}

      {hasRange === 'single' && includeTime && (
        <div className="single-date-time">
          <div className="half-width">
            <Input
              value={selectedDate}
              placeholder="Select date"
              clearValue={() => setSelectedDate(null)}
            />
          </div>
          -
          <div className="half-width">
            <TimeInput
              hour={startTime.hour}
              midday={startTime.midday}
              minute={startTime.minute}
              onChangeTime={changeStartTimeHandler}
              onBlur={onBlurHandlerStart}
              clearTime={clearStartTime}
            />
          </div>
        </div>
      )}

      {hasRange === 'range' && !includeTime && (
        <div className="single-date-time">
          <div className="half-width">
            <Input
              value={selectedDate?.from}
              placeholder="Select date"
              clearValue={() => setSelectedDate(null)}
            />
          </div>
          -
          <div className="half-width">
            <Input
              value={selectedDate?.to}
              placeholder="Select date"
              clearValue={() =>
                setSelectedDate((prev) => ({ from: prev.from, to: null }))
              }
            />
          </div>
        </div>
      )}

      {hasRange === 'range' && includeTime && (
        <div className="date-picker-range-time">
          <div className="single-date-time">
            <div className="half-width">
              <Input
                value={selectedDate?.from}
                placeholder="Select date"
                clearValue={() => setSelectedDate(null)}
              />
            </div>
            -
            <div className="half-width">
              <Input
                value={selectedDate?.to}
                placeholder="Select date"
                clearValue={() =>
                  setSelectedDate((prev) => ({ from: prev.from, to: null }))
                }
              />
            </div>
          </div>
          <div className="single-date-time">
            <div className="half-width">
              <TimeInput
                hour={startTime.hour}
                midday={startTime.midday}
                minute={startTime.minute}
                onChangeTime={changeStartTimeHandler}
                onBlur={onBlurHandlerStart}
                clearTime={clearStartTime}
              />
            </div>
            -
            <div className="half-width">
              <TimeInput
                hour={dueTime.hour}
                midday={dueTime.midday}
                minute={dueTime.minute}
                onChangeTime={changeDueTimeHandler}
                onBlur={onBlurHandlerDueTime}
                clearTime={clearDueTime}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function FooterDataPicker({
  selectedDate,
  setSelectedDate,
  startTime,
  setStartTime,
  dueTime,
  setDueTime,
  setHasRange,
  includeTime,
  setIncludeTime,
  hasRange,
  clearData,
  clearDate,
  hasReminder,
  setHasReminder,
  reminder,
  setReminder,
  setClearDate,
}) {
  const changeModeDateRange = (toggle) => {
    if (toggle) {
      setSelectedDate(null);
      setHasRange('single');
    } else {
      setSelectedDate(null);
      setHasRange('range');
    }
  };

  const changeModeTime = (toggle) => {
    setIncludeTime(!toggle);
  };

  const changeModeReminder = (toggle) => {
    setHasReminder(!toggle);
  };

  return (
    <div className="date-picker-footer-container">
      <ControlInputs
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        startTime={startTime}
        setStartTime={setStartTime}
        dueTime={dueTime}
        setDueTime={setDueTime}
        hasRange={hasRange}
        includeTime={includeTime}
      />
      <div className="date-picker-switch">
        <div className="date-picker-switch-container font-14-reg">
          Date range
          <SwitchButton
            onClick={changeModeDateRange}
            clearDate={clearDate}
            firstData={hasRange === 'range'}
            setClearDate={setClearDate}
          />
        </div>
        <div className="date-picker-switch-container font-14-reg">
          Include time
          <SwitchButton
            onClick={changeModeTime}
            clearDate={clearDate}
            firstData={includeTime}
            setClearDate={setClearDate}
          />
        </div>
        {selectedDate && (
          <div className="date-picker-switch-container font-14-reg">
            Due date reminder
            <SwitchButton
              onClick={changeModeReminder}
              clearDate={clearDate}
              firstData={hasReminder}
              setClearDate={setClearDate}
            />
          </div>
        )}
      </div>
      {hasReminder && (
        <select
          value={reminder}
          onChange={(e) => setReminder(e.target.value)}
          className="date-picker-reminder-container font-14-reg cursor-pointer"
        >
          <div className="calendar-reminder-selector">
            <div className="font-16-med calendar-reminder-selector-text">
              {/* {reminder?.name} */}
            </div>
            {SvgSelector('chevronDown')}
          </div>

          {reminderItems.map((item) => (
            <option
              key={item.id}
              className="calendar-reminder-selector-item font-14-reg hover"
              value={item.time}
            >
              {item.name}
            </option>
          ))}
        </select>
      )}
      <div
        className="date-picker-switch-container clear-data cursor-pointer"
        onClick={clearData}
      >
        <span className="font-14-med date-picker-delete-data">Clear all</span>
        <span className="font-12-reg date-picker-delete-data">Ctrl + D</span>
      </div>
    </div>
  );
}
let date = {};
let isRange;
let isReminder;
let hasTime;
let tempStartTime;
let tempDueTime;
function DatePicker({ node, onClose }) {
  const { treeData } = useContext(DataContext);

  const nodeData = treeData[node];
  const nodeDueDate = nodeData?.due_time ? new Date(nodeData?.due_time) : null;
  const dueTimeFt = nodeDueDate ? nodeDueDate.getUTCHours() : null;
  const nodeStartDate = nodeData?.start_time
    ? new Date(nodeData?.start_time)
    : null;
  const startTimeFt = nodeStartDate ? nodeStartDate.getUTCDate() : null;
  const nodeReminder = nodeData?.reminder;
  const nodeHasTime = nodeData?.due_time_has_time;

  const firstSelectDate = nodeStartDate
    ? { from: nodeStartDate, to: nodeDueDate }
    : nodeDueDate;
  const [selectedDate, setSelectedDate] = useState(firstSelectDate);

  const [hasRange, setHasRange] = useState(nodeStartDate ? 'range' : 'single');
  const [includeTime, setIncludeTime] = useState(nodeHasTime);
  const [startTime, setStartTime] = useState({
    hour: nodeDueDate
      ? zeroPad(new Date(nodeDueDate).getHours(), 2)
      : zeroPad(new Date().getHours() % 12, 2),
    minute: nodeDueDate
      ? zeroPad(new Date(nodeDueDate).getMinutes(), 2)
      : zeroPad(new Date().getMinutes(), 2),
    midday: nodeDueDate
      ? dueTimeFt < 12
        ? 'AM'
        : 'PM'
      : new Date().getHours() >= 12
        ? 'PM'
        : 'AM',
  });
  const [dueTime, setDueTime] = useState({
    hour:
      nodeStartDate === null && includeTime
        ? zeroPad(new Date(nodeDueDate).getHours(), 2)
        : nodeStartDate
          ? zeroPad(new Date(nodeStartDate).getHours() % 12, 2)
          : zeroPad(new Date().getHours() % 12, 2),
    minute:
      nodeStartDate === null && includeTime
        ? zeroPad(new Date(nodeDueDate).getMinutes(), 2)
        : nodeStartDate
          ? zeroPad(new Date(nodeStartDate).getMinutes(), 2)
          : zeroPad(new Date().getMinutes(), 2),
    midday: nodeStartDate
      ? startTimeFt < 12
        ? 'AM'
        : 'PM'
      : new Date().getHours() >= 12
        ? 'PM'
        : 'AM',
  });
  const [hasReminder, setHasReminder] = useState(!!nodeReminder);
  const [reminder, setReminder] = useState(nodeReminder);
  const [clearDate, setClearDate] = useState(false);

  const saveData = () => {
    if (!date) return;
    const startDate = isRange === 'range' ? date.from : null;
    const dueDate = isRange === 'range' ? date.to : date;

    const dueDateWithNewTime = dueDate
      ? new Date(
          dueDate.getFullYear(),
          dueDate.getMonth(),
          dueDate.getDate(),
          tempStartTime.midday === 'AM'
            ? tempStartTime.hour
            : Number(tempStartTime.hour) + 12,
          tempStartTime.minute,
        )
      : null;

    const startDateWithTime = startDate
      ? new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          tempDueTime.midday === 'AM'
            ? tempDueTime.hour
            : Number(tempDueTime.hour) + 12,
          tempDueTime.minute,
        )
      : null;
    onClose(startDateWithTime, dueDateWithNewTime, hasTime, isReminder);
  };

  useEffect(() => {
    date = selectedDate;
    isRange = hasRange;
    hasTime = includeTime;
    isReminder = Number(reminder);
    tempStartTime = startTime;
    tempDueTime = dueTime;
  }, [selectedDate, hasRange, reminder, includeTime, dueTime, startTime]);

  useEffect(() => {
    return () => {
      saveData();
    };
  }, []);

  const clearData = () => {
    setClearDate(true);
    setSelectedDate(null);
    setHasRange('single');
    setIncludeTime(false);
    setHasReminder(false);
    setStartTime({
      hour: zeroPad(new Date().getHours() % 12, 2),
      minute: zeroPad(new Date().getMinutes(), 2),
      midday: new Date().getHours() >= 12 ? 'PM' : 'AM',
    });
    setDueTime({
      hour: zeroPad(new Date().getHours() % 12, 2),
      minute: zeroPad(new Date().getMinutes(), 2),
      midday: new Date().getHours() >= 12 ? 'PM' : 'AM',
    });
  };

  const handleKeys = (e) => {
    if (e.ctrlKey && e.key === 'd') {
      e.preventDefault();
      clearData();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeys);

    return () => {
      window.removeEventListener('keydown', handleKeys);
    };
  }, []);

  return (
    <DayPicker
      mode={hasRange}
      selected={selectedDate}
      onSelect={setSelectedDate}
      footer={
        <FooterDataPicker
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          startTime={startTime}
          setStartTime={setStartTime}
          dueTime={dueTime}
          setDueTime={setDueTime}
          setHasRange={setHasRange}
          hasRange={hasRange}
          setIncludeTime={setIncludeTime}
          includeTime={includeTime}
          clearData={clearData}
          clearDate={clearDate}
          hasReminder={hasReminder}
          setHasReminder={setHasReminder}
          reminder={reminder}
          setReminder={setReminder}
          setClearDate={setClearDate}
        />
      }
    />
  );
}
export default DatePicker;
