import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';

const offset = 2;
function NodeConnectionLine({ itemEls, node }) {
  const { treeData, filterOptions, isDraggingNode } = useContext(DataContext);
  const parent = itemEls.current[node]?.parent;
  if (!itemEls.current[parent]?.element) return null;

  const calculateOpacity = () => {
    if (isDraggingNode) return '0.2';
    return filterNode(node) ? '1' : '0.1';
  };

  const filterNode = (item) => {
    if (
      filterOptions.statuses.length === 0 &&
      filterOptions.colors.length === 0
    )
      return true;
    if (
      filterOptions.statuses.includes(treeData[item]?.status) ||
      filterOptions.colors.includes(treeData[item]?.color)
    )
      return true;
    return false;
  };

  let top = 0;
  // eslint-disable-next-line
  const order = treeData[parent].order;
  const isNotDoneIds = order.filter(
    (i) => treeData[i].status !== filterOptions.hideDone[0],
  );
  const firstChildIdx = order.findIndex((i) => i === isNotDoneIds[0]);
  const lastChildIdx = order.findIndex((i) => i === isNotDoneIds.slice(-1)[0]);

  const lastElement = treeData[parent]?.order[lastChildIdx];
  const isLastElement = lastElement === node;
  const firstElement = treeData[parent]?.order[firstChildIdx];
  const isFirstElement = firstElement === node;

  const x1 =
    (itemEls?.current[parent]?.element?.offsetLeft || 0) +
    (itemEls?.current[parent]?.element?.clientWidth || 0) -
    5;
  const x2 = itemEls.current[node]?.element?.offsetLeft;
  const parentCenter =
    (itemEls.current[parent]?.element?.offsetTop || 0) +
    (itemEls.current[parent]?.element?.clientHeight || 0) / 2;
  const nodeWidth = itemEls.current[node]?.element?.clientWidth;
  const nodeHeight = itemEls.current[node]?.element?.clientHeight;
  const nodeTop = itemEls.current[node]?.element?.offsetTop;
  const cn = (nodeTop || 0) + (nodeHeight || 0) / 2;

  if (Math.abs(cn - parentCenter) < 3) top = nodeHeight / offset - 5;
  else if (cn < parentCenter) top = nodeHeight / offset - 3;
  else top = parentCenter - nodeTop - 3;

  const X = (x2 - x1) / 2;

  return (
    <div
      style={{
        zIndex: -1,
        position: 'absolute',
        top: top || 0,
        right: nodeWidth,
        height: Math.max(Math.abs(cn - parentCenter), 10) + 5.5,
        width: x2 - x1 || 0,
      }}
    >
      <svg height="100%" width="100%">
        {itemEls.current[node]?.element && itemEls.current[parent]?.element ? (
          <path
            className="connectors"
            strokeOpacity={calculateOpacity()}
            strokeWidth="1.2"
            fill="transparent"
            d={
              Math.abs(cn - parentCenter) < 3
                ? `M ${x2 - x1} ${3.5} L ${0} ${3.5}`
                : parentCenter > cn
                  ? `
                       M ${x2 - x1},${offset} a${X},15 0 0,0 -${X},14
                       ${
                         isFirstElement
                           ? ` M ${X} ${16} L ${X} ${parentCenter - cn - 7}
                       M ${0},${
                         parentCenter - cn + offset
                       } a${X},10 0 0,0 ${X},-9`
                           : ``
                       }
                       `
                  : `
                       M ${x2 - x1},${
                         cn - parentCenter
                       } a${X},17 0 0,1 -${X},-12
                      ${
                        isLastElement
                          ? `M ${X} ${cn - parentCenter - 12} L ${X} ${11}
                             M ${0},${offset} a${X},10 0 0,1 ${X},9`
                          : ``
                      }`
            }
          />
        ) : (
          <path />
        )}
      </svg>
    </div>
  );
}
export default NodeConnectionLine;
