import TableBody from './TableBody';

function MyFocus({
  myFocusTasks,
  setCheckMembers,
  memberList,
  taskFilter,
  setTaskFilter,
  calendarData,
  todayTasks,
  addNewTask,
  setToastInfo,
  toastInfo,
  updateStatus,
  deleteTask,
  duplicateTask,
}) {
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const timezoneOffset = date.getTimezoneOffset();

    date.setMinutes(date.getMinutes() - timezoneOffset);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="my-focus-container">
      {calendarData.map((i, index) => {
        const tasksDate = formatDate(i.currentDay);
        if (index < 7) {
          if (i.name === 'Today')
            return (
              <TableBody
                key={i.name}
                addNewTask={addNewTask}
                tasks={todayTasks}
                setCheckMembers={setCheckMembers}
                memberList={memberList}
                setTaskFilter={setTaskFilter}
                taskFilter={taskFilter}
                tasksDate={tasksDate}
                setToastInfo={setToastInfo}
                toastInfo={toastInfo}
                type="todayTasks"
                updateStatus={updateStatus}
                deleteTask={deleteTask}
                duplicateTask={duplicateTask}
              />
            );
          return (
            <TableBody
              key={i.name}
              addNewTask={addNewTask}
              tasks={myFocusTasks[tasksDate]}
              day={i.day}
              topic={i.name || i.month}
              setCheckMembers={setCheckMembers}
              memberList={memberList}
              setTaskFilter={setTaskFilter}
              taskFilter={taskFilter}
              tasksDate={tasksDate}
              setToastInfo={setToastInfo}
              toastInfo={toastInfo}
              updateStatus={updateStatus}
              deleteTask={deleteTask}
              type="focusWithDate"
              duplicateTask={duplicateTask}
            />
          );
        }
        const tasks = [];
        Object.entries(myFocusTasks).map(([key, value]) => {
          const targetDate = new Date(key);
          targetDate.setHours(0, 0, 0, 0);
          const startDate = new Date(i.currentDay);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(i.currentEndDay);
          endDate.setHours(0, 0, 0, 0);
          if (targetDate >= startDate && targetDate <= endDate) {
            value.flatMap((item) => tasks.push(item));
          }
          return null;
        });
        return (
          <TableBody
            key={i.name}
            addNewTask={addNewTask}
            tasks={tasks}
            day={i.day}
            topic={i.name || i.month}
            setCheckMembers={setCheckMembers}
            memberList={memberList}
            setTaskFilter={setTaskFilter}
            taskFilter={taskFilter}
            tasksDate={tasksDate}
            setToastInfo={setToastInfo}
            toastInfo={toastInfo}
            updateStatus={updateStatus}
            deleteTask={deleteTask}
            type="focusWithDate"
            duplicateTask={duplicateTask}
          />
        );
      })}
    </div>
  );
}

export default MyFocus;
